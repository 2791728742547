import React, {memo, useState} from 'react';

import {
    corporativeLogo,
    academyToggle,
    corporativeClose,
    corporativeBurger,
    corporativeCloseSide,
    corporativeArrowRight
} from "../../images";
import { Link } from 'gatsby';
import {AnchorLink} from "gatsby-plugin-anchor-links";

type Props = {
    image: string,
    actLink: string
}

const HeaderCorporative = ({image, actLink}: Props) => {

    const [isClosed, setIsClosed] = useState(true);
    const [sideMenuStatus, setSideMenuStatus] = useState(false);
    const [activeLink, setActiveLink] = useState(actLink)

    const handleMobileMenu = () => {
        setIsClosed(!isClosed);
    }

    const handleMenuItem = () => {
        setIsClosed(true);
    }

    const handleMenuItemAnchor = (linkname:string) => {
        setIsClosed(true);
        setActiveLink(linkname);
    }

    const handleSideMenu = () => {
        setSideMenuStatus(!sideMenuStatus);
    }

    const handleTiktokEvent = () => {
        if (typeof window !== "undefined") {
            if (window.ttq != null) {
                // window.fbq('track', 'Lead', {currency: "USD", value: 9.99});
                window.ttq.track('ClickButton');
            }
        }
    }

    return (
        <>
            <div className="corporativeHeader mbsmall">
                <div className="corporativeContainer verybig">
                    <div className="corporativeHeaderBox">
                        <div className="corporativeBurger" onClick={() => handleSideMenu()}>
                            {!sideMenuStatus ? <img src={corporativeBurger} alt="" /> : <img src={corporativeCloseSide} alt="" /> }
                        </div>
                        <a href="" className="corporativeHeaderLogo">
                            <img src={image} alt="" />
                        </a>
                        <nav className={`corporativeHeaderNav ${!isClosed ? 'active' : ''}`}>
                            <ul>
                                <li>
                                    <Link to="/"
                                        onClick={() => handleMenuItem()}
                                        className={`${(activeLink === 'home') ? 'active': ''}`} >Home</Link>
                                </li>
                                <li>
                                    <Link to="/#book"
                                        onClick={() => handleMenuItemAnchor('courses')}
                                        className={`${(activeLink === 'courses') ? 'active': ''}`}>Courses</Link>
                                </li>
                                <li>
                                    <Link to="/article"
                                        onClick={() => handleMenuItem()}
                                        className={`${(activeLink === 'blog') ? 'active': ''}`} >Blog</Link>
                                </li>
                                <li>
                                    <Link to="/#tools"
                                        onClick={() => handleMenuItemAnchor('tools')}
                                        className={`${(activeLink === 'tools') ? 'active': ''}`}>Tools</Link>
                                </li>
                                <li>
                                    <Link to="/affiliate"
                                        onClick={() => handleMenuItem()}
                                        className={`${(activeLink === 'affiliate') ? 'active': ''}`}>AFfiliate program</Link>
                                </li>
                            </ul>
                            <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140" onClick={() => handleTiktokEvent()} className="corporativeHeaderStart corporativeBtn">
                                <span>Get Started</span>
                            </a>
                        </nav>
            
                    </div>
                </div>
            </div>

            <div className="corporativeToggle" 
                onClick={() => handleMobileMenu()}
                >
                <img src={isClosed ? academyToggle : corporativeClose} alt="" />
            </div>

            <div className={`corporativeSidemenu ${sideMenuStatus ? 'active' : ''}`}>
                <ul className="corporativeSidemenuList">
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Billionaires</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Innovation</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Leadership</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Money</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Forbes Digital Assets</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Investment Newsletters</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Business</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Small Business</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Lifestyle</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Real Estate</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Forbes Store</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Vetted</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Coupons</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Advisor</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Health</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Lists</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                    <li className="corporativeSidemenuItem">
                        <div role="button" className="corporativeSidemenuLink">
                            <a href="">
                                <span>Video</span>
                                <img src={corporativeArrowRight} alt="" />
                            </a>
                        </div>
                        <div className="corporativeNestedList">
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                            <a href="" className="corporativeNestedListItem">Billionaires Inside</a>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default memo(HeaderCorporative);