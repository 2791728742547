import '../styles/pages/article.scss';
import * as React from "react"
import {HeadFC, Link, navigate, PageProps} from "gatsby"
import {useEffect, useMemo, useState, useRef} from "react";
import Slider from "react-slick";
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {
    corporativeGoogleplay,
    corporativeApple,
    corporativeShape1,
    corporativeShape2,
    corporativeShape1Mob,
    corporativeShape2Mob,
    corporativeStar,
    corporativeUser1,
    corporativeUser2,
    corporativeUser3,
    masterCardV2,
    VisaV2,
    BigLogoRight,
    AvatarImage,
    corporativeLearn,
    blogUserIcon,
    blogCalendarIcon,
    blogClockIcon,
    blogOwner,
    blogLinkedin,
    blogTwitter,
    blogFacebook,
    blogLogo
    
} from "../images";
import {toast, ToastContainer} from "react-toastify";
import HeaderCorporative from '../components/HeaderCorporative';

// type DataProps = {
//     path: string
// }

const settingsFeed = {
    dots: false,
    infinite: true,
    // speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
};



const ArticlePage = ({}: PageProps) => {

    const [isWideScreen, setIsWideScreen] = useState<boolean>(false);
    
    useEffect(() => {
        if (typeof window !== 'undefined'){
            setIsWideScreen(window.innerWidth > 767);

            const handleResize = () => {
                setIsWideScreen(window.innerWidth > 767);
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
        
    }, []);

    const renderFeed = () => {
        if(isWideScreen){
            return <div className="corporativeFeedBox">
                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser1} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Vishu</span>
                            <p>Painting Professional <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I was surprised by the amazing features of the app that I hadn’t known about before. The app provides quick insight into books, allowing me to read key ideas, quotes, and messages in literally 15 minutes. Hands down, one of the best apps I’ve come across</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 10, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser2} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Jared</span>
                            <p>Digital Marketing Maven <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>Listening to FeelGrow while running. It’s an app that provides thousands of insights from the world’s best books, courses, documentaries, and podcasts. So many titles to choose from.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 19, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser3} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Mike</span>
                            <p>Web Dev Enthusiast <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I always have LOVED learning. But sometimes, it feels overwhelming, especially when you don’t know where to start. There are so many topics, so many books to read, so many lessons out there. I am obsessed and had no idea this is what I needed in my life. I’m saving you so much time.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 30, 2023</span>
                </div>
            </div>
        } else{
            return <Slider initialSlide={0} {...settingsFeed} className="corporativeFeedBox">
                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser1} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Vishu</span>
                            <p>Painting Professional <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I was surprised by the amazing features of the app that I hadn’t known about before. The app provides quick insight into books, allowing me to read key ideas, quotes, and messages in literally 15 minutes. Hands down, one of the best apps I’ve come across</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 10, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser2} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Jared</span>
                            <p>Digital Marketing Maven <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>Listening to FeelGrow while running. It’s an app that provides thousands of insights from the world’s best books, courses, documentaries, and podcasts. So many titles to choose from.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 19, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser3} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Mike</span>
                            <p>Web Dev Enthusiast <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I always have LOVED learning. But sometimes, it feels overwhelming, especially when you don’t know where to start. There are so many topics, so many books to read, so many lessons out there. I am obsessed and had no idea this is what I needed in my life. I’m saving you so much time.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 30, 2023</span>
                </div>
            </Slider>
        }
    }


    const css = `
        html, body{
            background-color: #101010;
        }
    `;

    return (
        <main>
            <style>{css}</style>
            <div className="corporative">

                <HeaderCorporative image={blogLogo} actLink="blog" />

                <div className="articleBanner" id="home">
                    <div className="articleContainer big">
                        <div className="articleBannerBox">
                            <div className="articleBannerLeft">
                                <div className="articleBannerInfo">
                                    <div className="articleBannerInfoItem">
                                        <img src={blogUserIcon} alt="" />
                                        <span>FeelGrow Team</span>
                                    </div>
                                    <div className="articleBannerInfoItem">
                                        <img src={blogCalendarIcon} alt="" />
                                        <span>May 1, 2023</span>
                                    </div>
                                    <div className="articleBannerInfoItem">
                                        <img src={blogClockIcon} alt="" />
                                        <span>7 minutes</span>
                                    </div>
                                </div>
                                <h1 className="articleBannerTitle">Introducing FeelGrow Learning: a revolutionary new way of using FeelGrow</h1>
                                <p className="articleBannerDescr">Our mission is simple: to help people learn more, faster. But with over 1,000 ideas now available, we knew we wanted to create something that would help our users retain all this information at their fingertips. Cue FeelGrow Learning, our most important feature yet.</p>
                                <a href="" className="corporativeBtn articleBannerBtn">
                                    <span>More articles</span>
                                </a>
                            </div>
                            <div className="articleBannerRight">
                                <img src={blogOwner} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <picture className="corporativeFirstShape">
                    <source srcSet={corporativeShape1} media="(min-width: 768px)" />
                    <img src={corporativeShape1Mob} />
                </picture>

                <div className="articleContent">
                    <div className="articleContainer small">
                        <div className="articleContentArea">
                            <h2>Students around the globe are returning to school this week, but the team at FeelGrow believe that learning shouldn't end once you've left the classroom</h2>
                            <h6>What is FeelGrow Learning?</h6>
                            <p>We care about our users' long-term growth here at FeelGrow. We've worked hard to curate a library of over 1,000 book, course, documentary and podcast Hacks, but we also recognize that simply discovering these ideas isn't what is going to help you start using them. You'll also need to revisit them in regular intervals if they're going to sink in long-term.</p>
                            <p>Simply put, we don't just want to show you cool insights. We also want to help you learn them.</p>
                            <p>This big new learning feature of ours combining techniques such as Spaced Repetition, Metacognition, and Active Recall, built to help users retain the information they find on FeelGrow.</p>

                            <h6>Scientifically-proven techniques</h6>
                            <p>Let us set the scene: in 1885, German psychologist Hermann Ebbinghaus hypothesized a curve that suggested we forget up to half of what we learn within a matter of days.</p>
                            <p>We then continue to forget information over time, albeit at a decreasing rate. Spaced repetition is a technique that helps us overcome Ebbinghaus' forgetting curve.</p>
                            <p>Usually implemented with flashcards, small chunks of information are presented over time, at varying intervals. ​​Learning and memory are largely influenced by two key factors; how much knowledge we retain and the amount of effort spent retaining that information.</p>

                            <h6>Why is this so exciting?</h6>
                            <p>FeelGrow Learning switches your usage of the app from passive -> active.</p>
                            <p>We first built Sparks on FeelGrow to help you save all your 'lightbulb' moments. But we also believe it's our job to help you retain those bite-sized pieces of information. How do we do this? With spaced repetition.</p>
                            <p>Scientific research suggests that over a four-month period with 30 minutes of active usage a day, you can expect to learn (and retain!) 3600 flashcards of information with 90-95% accuracy. Whew!</p>

                            <h6>So, how does it work?</h6>
                            <p>The feature combines proven memory and knowledge retention strategies such as Spaced Repetition, Metacognition, and Active Recall into one seamless experience.</p>
                            <p>In our new "Repetition" tab (previously known as "Notes"), users can now access a personalized stream of cards with all the content from FeelGrow they've studied and saved.</p>
                            <p>FeelGrow Learning brings these cards to the forefront at incremental intervals, also prompting the user to gauge their confidence in remembering them.</p>
                            <p>If a user feels they're confident, they'll be tested with this card again later – but this time, our AI will select crucial content to be simplified, compelling the brain to activate its memory recall, before once again querying the user if they recollect it.</p>
                            <p>Users will be provided with a set of cards to review at regular intervals, based on whether they remember them or not. After completing the card reviews for the day, they'll be encouraged to delve deeper into the "Goals" section to discover more and further their growth.</p>

                            <h6>Free for all users</h6>
                            <p>This feature is available to both free and Premium users - the more content on FeelGrow you've engaged with, the more flashcards you'll be able to learn from. You can also opt to just feature the content you've specifically Saved, if you prefer.</p>
                            <p>This is a huge step in nurturing our users' personal growth - and we've even featured daily tasks on our homepage to help users build a new daily learning habit.</p>
                            <p>Just imagine how much you could learn, right from your fingertips…</p>
                            <p>FeelGrow Learning is now available to all users, and we can’t wait to hear how it revolutionizes the way you learn. Our users are at the heart of everything we do here at FeelGrow so please let us know how you get on by tagging us on social.</p>
                        </div>
                        <div className="articleFollow">
                            <span className="articleFollowTitle">Free for all users</span>
                            <div className="articleFollowSoc">
                                <div className="articleFollowSocItem">
                                    <img src={blogLinkedin} alt="" />
                                    <span>Instagram</span>
                                </div>
                                <div className="articleFollowSocItem">
                                    <img src={blogTwitter} alt="" />
                                    <span>Twitter</span>
                                </div>
                                <div className="articleFollowSocItem">
                                    <img src={blogFacebook} alt="" />
                                    <span>Facebook</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="corporativeShapeCont">
                    <picture className="corporativeShapeTop">
                        <source srcSet={corporativeShape2} media="(min-width: 768px)" />
                        <img src={corporativeShape2Mob} />
                    </picture>
                    <div className="corporativeLearn">
                        <h2 className="corporativeLearnTitle">LEARN SOMETHING NEW IN 15 MINUTES A DAY</h2>
                        <div className="corporativeLearnBox">
                            <div className="corporativeLearnImage">
                                <img src={corporativeLearn} alt="" />
                            </div>
                            <div className="corporativeLearnInfo">
                                <ul className="corporativeLearnList">
                                    <li className="active">Feed your brain while</li>
                                    <li>Driving</li>
                                    <li>Commuting</li>
                                    <li>Doing housework</li>
                                    <li>Walking</li>
                                    <li>Relaxing</li>
                                </ul>
                                <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140">
                                    <button className="corporativeLearnBtn corporativeBtn">
                                        <span>Get Started</span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <picture className="corporativeShapeBott">
                        <source srcSet={corporativeShape1} media="(min-width: 768px)" />
                        <img src={corporativeShape1Mob} />
                    </picture>
                </div>

                <div className="corporativeFeed">
                    <div className="corporativeContainer">
                        <h2 className="corporativeFeedTitle">What our users are saying</h2>
                        {renderFeed()}
                    </div>
                </div>
                <footer className="corporativeFooter">
                    <div className="corporativeContainer">
                        <div className="corporativeFooterBox">
                            <div className="corporativeFooterLeft">
                                <div className="corporativeFooterUser">
                                    <img src={AvatarImage} alt="" />
                                </div>
                                <div className="corporativeFooterInfo">
                                    <span>CEO</span>
                                    <b>Andrew Filatov</b>
                                    <p>My goal is to create a product that <br /> helps people improve themselves</p>
                                </div>
                            </div>
                            <div className="corporativeFooterCenter">
                                <div className="corporativeFooterSoc desk">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <div className="corporativeFooterPayment">
                                    <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140">
                                        <img src={corporativeApple} alt="" />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140">
                                        <img src={corporativeGoogleplay} alt="" />
                                    </a>
                                </div>

                                <div className="corporativeFooterNav">
                                    <Link to="/terms">Terms of User</Link>
                                    <Link to="/privacy">Privacy policy</Link>
                                    <Link to="/cookie">Cookie policy</Link>
                                    <Link to="/cancel">Unsubscribe</Link>
                                </div>
                            </div>
                            <div className="corporativeFooterRight">
                                <div className="corporativeFooterSoc mob">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <img src={BigLogoRight} alt="" className="corporativeFooterLogo" />
                                <p>© AEER PLATFORM INC <br />
                                8 THE GREEN STE A DOVER, DE 19901</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

            <ToastContainer/>
        </main>
    )
}

export default ArticlePage

export const Head: HeadFC = () => <title>AEER Platform</title>
